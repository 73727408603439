/*
 * React Multi Date Picker 套件，官方 Styling 文件
 * https://shahabyazdi.github.io/react-multi-date-picker/classes-&-styles/
*/

.cm-calendar-container {
  width: 100%;
}
.cm-calendar-input {
  background-color: var(--colors-white);
  border-color: var(--colors-secondary20);
  height: 30px;
  width: 100%;
  border-radius: var(--radii-5);
  border-style: solid;
  border-width: 1px;
  font-size: var(--fontSizes-14);
  transition: border 0.15s linear;
  text-indent: var(--space-2);
}
.cm-calendar-input::placeholder {
  color: 1px solid var(--colors-secondary30);
}
.cm-calendar-input:focus {
  outline: none;
  border: 1px solid var(--colors-secondary40);
}
.cm-calendar-input:disabled {
  border-color: var(--colors-secondary10);
  background-color: var(--colors-secondary5);
  color: var(--colors-secondary30);
}

.cm-calendar-input:focus {
  outline: none;
  border: 1px solid var(--colors-secondary40);
}

.cm-calendar.rmdp-shadow {
  box-shadow: var(--shadows-basic);
}
.rmdp-arrow {
  border: solid var(--colors-primary50);
  border-width: 0 2px 2px 0;
}
.rmdp-arrow-container:hover {
  background-color: var(--colors-primary50);
  box-shadow: var(--shadows-basic);
}
.cm-calendar .rmdp-week-day {
  color: var(--colors-primary50);
}
.cm-calendar .rmdp-day.rmdp-today span {
  background-color: var(--colors-primary30);
}
.cm-calendar .rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: var(--colors-primary50);
  box-shadow: var(--shadows-basic);
}
.cm-calendar .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
  background-color: var(--colors-primary40);
}

.rmdp-container .ep-arrow.cm-calendar-arrow::after {
  background-color: var(--colors-white);
}
.rmdp-ep-shadow::after {
  box-shadow: var(--shadows-basic);
}

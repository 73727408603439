.gutter {
  border-top: 1px solid var(--colors-secondary20);
  background-color: var(--colors-white);
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-vertical {
  position: relative;
  cursor: row-resize;
}
.gutter.gutter-vertical::before {
  position: absolute;
  content: '';
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 4px;
  width: 40px;
  background-color: var(--colors-secondary20);
  border-top-right-radius: var(--radii-5);
  border-top-left-radius: var(--radii-5);
  border-bottom-right-radius: var(--radii-5);
  border-bottom-left-radius: var(--radii-5);
}
